import {
    Box,
    Flex,
    IconButton,
    Stack,
    Collapse,
    Icon,
    useDisclosure,
    Container,
} from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FiMenu, FiX, FiShoppingCart } from 'react-icons/fi';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';
import { SanitySiteSettings } from '../../../graphql-types';
import useLocale from '../../lib/useLocale';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

interface NavbarProps {
    siteSettings: SanitySiteSettings;
}

export default function Navbar({ siteSettings }: NavbarProps) {
    const { isOpen, onToggle } = useDisclosure();
    const { format } = useLocale();
    return (
        <Box bg="primary.100">
            <Container
                // maxW="container.xl"
                maxW="8xl"
            >
                <Flex minH="60px" py={{ base: 2 }} align="center">
                    <Flex
                        flex={{ base: 1, md: 'auto' }}
                        ml={{ base: -2 }}
                        display={{ base: 'flex', md: 'none' }}
                    >
                        <IconButton
                            onClick={onToggle}
                            icon={
                                <Icon w={5} h={5} as={isOpen ? FiX : FiMenu} />
                            }
                            variant="ghost"
                            aria-label={
                                isOpen
                                    ? format('closeMenu')
                                    : format('openMenu')
                            }
                        />
                    </Flex>
                    <Flex
                        flex={{ base: 1 }}
                        justify={{ base: 'center', md: 'start' }}
                    >
                        <Box w="7em" py={1} boxSizing="border-box">
                            <GatsbyLink to="/">
                                {siteSettings?.logo?.asset &&
                                    siteSettings?.title && (
                                        <GatsbyImage
                                            image={
                                                siteSettings.logo.asset
                                                    .gatsbyImageData
                                            }
                                            alt={siteSettings.title}
                                        />
                                    )}
                            </GatsbyLink>
                        </Box>
                    </Flex>
                    <Flex
                        display={{
                            base: 'none',
                            md: 'flex',
                        }}
                        flex={1}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <DesktopNav
                            navigationItems={siteSettings?.mainNavigation || []}
                        />
                    </Flex>
                    <Stack
                        flex={{ base: 1 }}
                        justify="flex-end"
                        direction="row"
                        spacing={6}
                    >
                        <IconButton
                            className="snipcart-checkout"
                            icon={<Icon w={6} h={6} as={FiShoppingCart} />}
                            variant="ghost"
                            aria-label={format('viewShoppingCart')}
                        />
                    </Stack>
                </Flex>
                <Collapse in={isOpen} animateOpacity>
                    <MobileNav
                        navigationItems={siteSettings?.mainNavigation || []}
                    />
                </Collapse>
            </Container>
        </Box>
    );
}
