module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"En.Base.Studio","short_name":"en.base.studio","start_url":"/","background_color":"#FFFFFF","theme_color":"#F5CBB7","display":"minimal-ui","icon":"src/images/icon-square-black.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7fefc0bdbfb893180da93eb5dba8a13f"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["es","en"],"defaultLanguage":"es","generateDefaultLanguagePage":true,"redirect":false,"siteUrl":"https://enbasestudio.gatsbyjs.io/","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.2.0","publicApiKey":"YTkyMWVmZGQtOThlYi00YzM4LTliNGYtMzJiNTZjMGYxZTM2NjM3MzA3NzIwMzg4MTI0ODEw","defaultLang":"es","currency":"usd","openCartOnAdd":true,"useSideCart":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
