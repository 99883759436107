import { cloneElement, createElement } from 'react';
import type { GatsbyBrowser } from 'gatsby';
import Layout from './src/components/Layout';
// import './src/lib/snipcart-styles.css';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
    element,
}) => {
    const newElement = cloneElement(
        element, // I18nextProvider
        element.props,
        cloneElement(
            element.props.children, // I18nextContext.Provider
            element.props.children.props,
            createElement(
                Layout,
                undefined,
                element.props.children.props.children
            )
        )
    );

    return newElement;
};
