import { useEffect } from 'react';

const updateProducts = async cardState => {
    try {
        await fetch(`/api/inventory`, {
            method: `POST`,
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(cardState),
        });
    } catch (error) {
        console.log('error', error);
    }
};

export default function useSnipcartEvent() {
    useEffect(() => {
        const { Snipcart } = window as any;
        if (!Snipcart) return;
        // updates inventory when order is placed
        Snipcart.events.on('cart.confirmed', cartConfirmResponse => {
            // console.log(cartConfirmResponse);
            updateProducts(cartConfirmResponse);
        });
    }, []);
}
