import type { ReactNode } from 'react';
import { Grid } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import Navbar from './Navbar';
import Footer from './Footer';
import Fonts from './Fonts';
import ThemeProvider from './ThemeProvider';
import {
    GetSiteSettingsQuery,
    SanitySiteSettings,
} from '../../../graphql-types';
import useSnipcartEvent from '../../lib/useSnipcartEvent';

export const SITE_SETTINGS_QUERY = graphql`
    query getSiteSettings {
        siteSettings: sanitySiteSettings {
            _id
            title
            primaryColor {
                hex
            }
            secondaryColor {
                hex
            }
            logo {
                asset {
                    gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
                }
            }
            mainNavigation {
                ...SanityNavItemFragment
            }
            footerNavigationStore {
                ...SanityNavItemFragment
            }
            footerNavigationInfo {
                ...SanityNavItemFragment
            }
        }
    }
`;

interface LayoutProps {
    children: ReactNode;
}

export default function Layout({ children }: LayoutProps) {
    const { siteSettings }: GetSiteSettingsQuery =
        useStaticQuery(SITE_SETTINGS_QUERY);
    useSnipcartEvent();

    return (
        <ThemeProvider
            primaryColor={siteSettings?.primaryColor}
            secondaryColor={siteSettings?.secondaryColor}
        >
            <Grid minH="100vh" templateRows="auto 1fr auto">
                <Fonts />
                <Navbar siteSettings={siteSettings as SanitySiteSettings} />
                {children}
                <Footer siteSettings={siteSettings as SanitySiteSettings} />
            </Grid>
        </ThemeProvider>
    );
}
