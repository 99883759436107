import { ReactNode, useMemo } from 'react';
import { extendTheme, ChakraProvider } from '@chakra-ui/react';
import { generateFontFamily, generatePalette } from 'palette-by-numbers';
import { mode } from '@chakra-ui/theme-tools';

function generateTheme(primary: string, secondary: string) {
    const colors = {
        primary: generatePalette(primary),
        secondary: generatePalette(secondary),
    };
    return extendTheme({
        colors,
        config: {
            initialColorMode: 'light',
            // useSystemColorMode: true,
        },
        fonts: {
            body: generateFontFamily('sans-serif', 'Montserrat'),
            heading: generateFontFamily('sans-serif', 'Montserrat'),
            // heading: 'Bebas Neue, cursive',
            // mono: 'Menlo, monospace',
        },
        styles: {
            global: props => ({
                body: {
                    // fontFamily: 'body',
                    color: mode('primary.900', 'primary.100')(props),
                    // bg: mode('primary.50', '#222831')(props),
                    // lineHeight: 'base',
                },
            }),
        },
        shadows: {
            outline: `0 0 0 3px ${primary}`,
        },
        components: {
            Heading: {
                // 1. We can update the base styles
                baseStyle: {
                    fontWeight: 'normal', // Normally, it is "semibold"
                },
            },
        },
    });
}

interface ThemeProviderProps {
    children: ReactNode;
    primaryColor?: { hex?: string | null | undefined } | null | undefined;
    secondaryColor?: { hex?: string | null | undefined } | null | undefined;
}

export default function ThemeProvider({
    children,
    primaryColor,
    secondaryColor,
}: ThemeProviderProps) {
    const theme = useMemo(
        () => generateTheme(primaryColor?.hex || '', secondaryColor?.hex || ''),
        [primaryColor, secondaryColor]
    );
    return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}
