import { Flex, Text, Stack, Link } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';
import { Maybe, SanityNavItem } from '../../../graphql-types';
import useLocale from '../../lib/useLocale';

interface MobileNavProps {
    navigationItems: Array<Maybe<SanityNavItem>>;
}

export default function MobileNav({ navigationItems }: MobileNavProps) {
    const { translate } = useLocale();
    return (
        <Stack p={4} display={{ md: 'none' }}>
            {navigationItems?.map(navItem => (
                <Stack spacing={4} key={navItem?._key}>
                    <Flex
                        py={2}
                        as={navItem?.route ? GatsbyLink : Link}
                        to={
                            navItem?.route
                                ? navItem?.route
                                : navItem?.link || ''
                        }
                        justify="space-between"
                        align="center"
                        _hover={{
                            textDecoration: 'none',
                        }}
                    >
                        <Text fontWeight={600}>
                            {' '}
                            {navItem?.title ? translate(navItem?.title) : ''}
                        </Text>
                    </Flex>
                </Stack>
            ))}
        </Stack>
    );
}
