import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next';
import { useContext } from 'react';
import {
    SanityLocaleBlockContent,
    SanityLocaleString,
} from '../../graphql-types';

export default function useLocale() {
    const { t } = useTranslation();
    const context = useContext(I18nextContext);

    function format(key: string) {
        return t(key);
    }

    function translate(text: SanityLocaleString): string {
        const ba = text[context.language];
        const foo = text.es;
        return ba || foo || '';
    }

    function translateBlock(block: SanityLocaleBlockContent) {
        if (context.language === 'en') return block._rawEn;
        return block._rawEs;
    }

    return {
        translate,
        translateBlock,
        format,
    };
}
