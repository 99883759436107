exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acerca-de-en-base-studio-tsx": () => import("./../../../src/pages/acerca-de-en-base-studio.tsx" /* webpackChunkName: "component---src-pages-acerca-de-en-base-studio-tsx" */),
  "component---src-pages-catalogo-tsx": () => import("./../../../src/pages/catalogo.tsx" /* webpackChunkName: "component---src-pages-catalogo-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-cuidados-tsx": () => import("./../../../src/pages/cuidados.tsx" /* webpackChunkName: "component---src-pages-cuidados-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politica-de-privacidad-tsx": () => import("./../../../src/pages/politica-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidad-tsx" */),
  "component---src-pages-preguntas-frecuentes-tsx": () => import("./../../../src/pages/preguntas-frecuentes.tsx" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-tsx" */),
  "component---src-pages-puntos-de-venta-tsx": () => import("./../../../src/pages/puntos-de-venta.tsx" /* webpackChunkName: "component---src-pages-puntos-de-venta-tsx" */),
  "component---src-pages-terminos-y-condiciones-tsx": () => import("./../../../src/pages/terminos-y-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-tsx" */),
  "component---src-templates-categories-template-tsx": () => import("./../../../src/templates/CategoriesTemplate.tsx" /* webpackChunkName: "component---src-templates-categories-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/ProductTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */)
}

