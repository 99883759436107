import {
    Box,
    chakra,
    Container,
    Link,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden,
    useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SanitySiteSettings } from '../../../graphql-types';
import useLocale from '../../lib/useLocale';

interface FooterProps {
    siteSettings: SanitySiteSettings;
}

const SocialButton = ({
    children,
    label,
    href,
}: {
    children: ReactNode;
    label: string;
    href: string;
}) => (
    <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded="full"
        w={10}
        h={10}
        cursor="pointer"
        as="a"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        transition="background 0.3s ease"
        _hover={{
            bg: useColorModeValue('blackAlpha.300', 'whiteAlpha.300'),
        }}
    >
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
    </chakra.button>
);

const ListHeader = ({ children }: { children: ReactNode }) => (
    <Text fontWeight="500" fontSize="lg" mb={2}>
        {children}
    </Text>
);

export default function Footer({ siteSettings }: FooterProps) {
    const { translate, format } = useLocale();

    return (
        <Box bg="primary.50" as="footer">
            <Container as={Stack} maxW="container.lg" py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr' }}
                    spacing={8}
                >
                    <Stack
                        spacing={6}
                        alignItems={{ base: 'center', sm: 'flex-start' }}
                    >
                        <Box
                            w="10em"
                            boxSizing="border-box"
                            as={GatsbyLink}
                            to="/"
                        >
                            {siteSettings?.logo?.asset &&
                                siteSettings?.title && (
                                    <GatsbyImage
                                        image={
                                            siteSettings.logo.asset
                                                .gatsbyImageData
                                        }
                                        alt={siteSettings.title}
                                    />
                                )}
                        </Box>
                        <Text fontSize="sm">
                            © {new Date().getFullYear()} En Base Studio.{' '}
                            {format('allRightsReserved')}
                        </Text>
                        <Stack direction="row" spacing={6}>
                            <SocialButton
                                label="Instagram"
                                href="https://www.instagram.com/en.base.studio/"
                            >
                                <FaInstagram size="1.4em" />
                            </SocialButton>
                            <SocialButton
                                label="Whatsapp"
                                href="https://wa.me/50684010525"
                            >
                                <FaWhatsapp size="1.4em" />
                            </SocialButton>
                            <SocialButton
                                label="Email"
                                href="mailto:en.base.studio@gmail.com"
                            >
                                <FiMail size="1.5em" />
                            </SocialButton>
                        </Stack>
                    </Stack>
                    <Stack align="flex-start">
                        <ListHeader>{format('shop')}</ListHeader>
                        {siteSettings?.footerNavigationStore?.map(item =>
                            item ? (
                                <Link
                                    as={GatsbyLink}
                                    key={item._key}
                                    to={item?.route || '/'}
                                >
                                    {item?.title && translate(item.title)}
                                </Link>
                            ) : null
                        )}
                    </Stack>
                    <Stack align="flex-start">
                        <ListHeader>{format('info')}</ListHeader>
                        {siteSettings?.footerNavigationInfo?.map(item =>
                            item ? (
                                <Link
                                    as={GatsbyLink}
                                    key={item._key}
                                    to={item?.route || '/'}
                                >
                                    {item?.title && translate(item.title)}
                                </Link>
                            ) : null
                        )}
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    );
}
