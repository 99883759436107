import { Box, Stack, Link } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';
import { Maybe, SanityNavItem } from '../../../graphql-types';
import useLocale from '../../lib/useLocale';

interface DesktopNavProps {
    navigationItems: Array<Maybe<SanityNavItem>>;
}

export default function DesktopNav({ navigationItems }: DesktopNavProps) {
    const { translate } = useLocale();
    return (
        <Stack direction="row" spacing={6}>
            {navigationItems?.map(navItem => (
                <Box key={navItem?._key} display="flex" alignItems="center">
                    <Link
                        p={2}
                        as={navItem?.route ? GatsbyLink : Link}
                        to={
                            navItem?.route
                                ? navItem?.route
                                : navItem?.link || ''
                        }
                        fontSize="md"
                        fontWeight={500}
                        _hover={{
                            textDecoration: 'none',
                            color: 'primary.600',
                            fontWeight: 'bold',
                        }}
                    >
                        {navItem?.title ? translate(navItem?.title) : ''}
                    </Link>
                </Box>
            ))}
        </Stack>
    );
}
